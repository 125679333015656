import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
class About extends Component{
    render(){
        let title = 'Over Kilic',
        description = "Ik sta bekend als de rustige, betrouwbare vakman die breed inzetbaar is voor velerlei opdrachten. Hierbij kunt u denken, tegelen, elektra, water/afvoer aanleggen enzovoort. Waar ik zeer veel ervaring mee heb is renoveren van badkamers en toiletten. Elke keer weer vind ik het een uitdaging om uw oude badkamer te transformeren in een nieuwe badkamer. Ik kan u hierover adviseren, helpen met de indeling, eventueel met u mee naar een showroom, het materiaal bestellen, plaatsen en natuurlijk prachtig afwerken. Kortom zo’n project is bij mij van het begin tot het eind in goede handen. Dat geeft u zekerheid en veiligheid, een stukje vertrouwd verbouwen.";
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Over ' />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title">Over ons</h2>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                 {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                
                <ContactTwo />
                
            </div>       

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About
import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import { FiEdit3 , FiBriefcase ,FiChevronUp, FiCheck } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import { portfolioSlick2 } from "../page-demo/script";
const SlideList = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--31',
        category: '',
        title: 'Klussenbedrijf Kilic',
        description: 'Klussenbedrijf Kilic is een klusbedrijf in Hilversum. Wij verrichten diensten in en rondom Hilversum. Ik sta bekend als de rustige, betrouwbare vakman die breed inzetbaar is voor velerlei opdrachten. Hierbij kunt u denken, tegelen, , elektra, water/afvoer aanleggen',
        buttonText: 'Neem contact op!',
        buttonLink: '/contact'
    }
]

const PortfolioList2 = [
    {
        image: 'image-1'
    },
    {
        image: 'image-2'
    },
    {
        image: 'image-3'
    },
    {
        image: 'image-4'
    },
    {
        image: 'image-1'
    },
    {
        image: 'image-2'
    },
    {
        image: 'image-3'
    },
    {
        image: 'image-4'
    },
    {
        image: 'image-1'
    },
    {
        image: 'image-2'
    },
    {
        image: 'image-4'
    }
]


const ServiceListOne = [
    {
        icon: <FiEdit3 />,
        title: 'Diensten',
        description: 'Kilic is een veelzijdig klusbedrijf: U kunt ons dus veel verschillende werkzaamheden laten uitvoeren.'
    },
    {
        icon: <FiCheck />,
        title: 'Werkwijze',
        description: 'Wij komen graag bij u langs om een offerte gesprek te houden.'
    },
    {
        icon: <FiBriefcase />,
        title: 'Garantie',
        description: 'Op elke klus die door ons wordt uitgevoerd heeft u één jaar garantie.'
    },
]

const starndardService = [
    {
        image: '01',
        title: 'Betrouwbaar en kwaliteit',
        description: 'Op elke klus die door ons wordt uitgevoerd heeft u één jaar garantie. Kilic is een nette klusser en gaat respectvol om met ui huis en bezittingen. ',
    },
    {
        image: '02',
        title: 'Goedkoper en flexibeler dan anderen',
        description: 'Kilic rekent geen voorrijkosten en kan kosten besparen door zelf het nodige gereedschap en expertise in huis te hebben.',
    },
    {
        image: '03',
        title: 'Goede communicatie',
        description: 'Ons bedrijf neemt je graag in alles mee met wat er bij een verbouwing van je badkamer komt kijken. Wat vinden wij belangrijk? Dat jij altijd tevreden bent',
    },

]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                {/* Start Service Area */}
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">Diensten</h2>
                                    <p>Is je badkamer toe aan een vernieuwingsslag? Wij zijn gespecialiseerd in het renoveren en verbouwen van badkamers en door onze jarenlange ervaring weten we precies wat er nodig is om jouw badkamer met succes grondig te renoveren.</p>
                                  
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {starndardService.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/featured/corporate-${value.image}.jpg`} alt="Corporate Images"/>
                                                </div>
                                                <div className="content">
                                                    <h3><a href="/service-details">{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                    <a className="btn-transparent rn-btn-dark" href="/service-details"><span className="text">Meer Info</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Featured Service Area  */}

              {/* Start Portfolio Area */}
              <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Gallerij</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--70 mt_sm--40">
                            <Slider {...portfolioSlick2}>
                                {PortfolioList2.map((value , index) => (
                                    <div className="portfolio" key={index}>
                                     
                                            <div className={`thumbnail ${value.image}`}></div>
                                        
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Over ons</h2>
                                        <p className="description">Ik sta bekend als de rustige, betrouwbare vakman die breed inzetbaar is voor velerlei opdrachten. Hierbij kunt u denken, tegelen, , elektra, water/afvoer aanleggen enzovoort.
                                            Waar ik zeer veel ervaring mee heb is renoveren van badkamers en toiletten. 
                                            Elke keer weer vind ik het een uitdaging om uw oude badkamer te transformeren in een nieuwe badkamer. 
                                            Ik kan u hierover adviseren, helpen met de indeling, eventueel met u mee naar een showroom, het materiaal bestellen, plaatsen en natuurlijk prachtig afwerken. Kortom zo’n project is bij mij van het begin tot het eind in goede handen. Dat geeft u zekerheid en veiligheid, een stukje vertrouwd verbouwen.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/about-3.png" alt="About Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}



           
   
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>


                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import ContactTwo from "../elements/contact/ContactTwo";
class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title">Diensten</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Badkamer renovaties</h4>
                                                    <p>Is je badkamer toe aan een vernieuwingsslag? Wij zijn gespecialiseerd in het renoveren en verbouwen van badkamers en door onze jarenlange ervaring weten we precies wat er nodig is om jouw badkamer met succes grondig te renoveren. </p>
                                                    <p>Hierbij vinden we het belangrijk dat alle functionaliteiten die jouw badkamer nodig heeft, aanwezig zijn. Natuurlijk gaat dit nooit ten koste van de kwaliteit van oplevering en materiaal. Benieuwd hoe we dat realiseren?</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Goede communicatie is voor ons heel belangrijk</h4>
                                                    <p>Ons bedrijf neemt je graag in alles mee met wat er bij een verbouwing van je badkamer komt kijken. Wat vinden wij belangrijk? Dat jij altijd tevreden bent. Daarom plaatsen wij niet zomaar een badkamer, maar kunnen je ook ondersteunen in het aankoopproces van de badkamer en materiaal.</p>
                                                    <p>Er vindt tijdens het proces veel communicatie plaats tussen klant en aannemer, zodat je op de hoogte blijft van alles wat er tijdens de verbouwing van je badkamer gebeurt. We bespreken het dan ook met u als er bepaalde keuzes moeten worden gemaakt. Op die manier kom je nooit voor verrassingen te staan.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-03.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Benieuwd hoe wij het doen?</h4>
                                                    <h3 className="title">Slopen</h3>
                                                    <p>Het project begint normaal met het afschermen en afplakken van het looppad naar de badkamer. Op de eerste dag, soms wel de eerste twee dagen, wordt de badkamer gesloopt. Al het afval komt in de container die direct bij de ingang wordt geplaatst. Meestal zijn dit 3-6 m³ afvalcontainers.</p>
                                                    <br></br>
                                                    <h3 className="title">Leidingwerk</h3>
                                                    <p>Daarna is het meestal nodig om de leidingwerk aan te passen en infrezen ,hier kunt u aan denken ,waterleiding ,radiatorleidingen, afvoer ,elektriciteit leidingen</p>
                                                    <br></br>
                                                    <h3 className="title">Betegelen, voegen en knippen</h3>
                                                    <p>Als we alles hebben voorbereid kunnen we starten met tegelen ,en in overleg met de klant wordt ook besproken over eventuele leg patroon van de tegels ,kleur van voeg en kit.</p>
                                                    <br></br>
                                                    <h3 className="title">Montage</h3>
                                                    <p>Montage gebeurt meestal op de één na laatste en laatste dag van de verbouwing.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        
            

                      
                 
               
             {/* Start Contact Page Area  */}
             <div className="rn-contact-page ptb--120 bg_color--1">
                
                    <ContactTwo />
                    
                </div>
                {/* End Contact Page Area  */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;